'use strict'

const li        = document.querySelectorAll('.li')
const bloqueContenido    = document.querySelectorAll('.bloqueContenido')


// CLICK en li
    // TODOS .li quitar la clase activo
    // TODOS .bloqueContenido quitar la clase activo
    // .li con la posicion se añadimos la clase activo
    // .bloqueContenido con la posicion se añadimos la clase activo

// Recorriendo todos los LI
li.forEach( ( cadaLi , i )=>{
    // Asignando un CLICK a CADALI
    li[i].addEventListener('click',()=>{

        // Recorrer TODOS los .li
        li.forEach( ( cadaLi , i )=>{
            // Quitando la clase activo de cada li
            li[i].classList.remove('liActivo')
            // Quitando la clase activo de cada bloqueContenido
            bloqueContenido[i].classList.remove('bloqueContenidoActivo')
        })

        // En el li que hemos click le añadimos la clase activo
        li[i].classList.add('liActivo')
        // En el bloqueContenido con la misma posición le añadimos la clase activo
        bloqueContenido[i].classList.add('bloqueContenidoActivo')

    })
})


const bloque = document.querySelectorAll(".bloque")
const fas    = document.querySelectorAll('.fas')

bloque.forEach(h3s => {
  h3s.addEventListener("click", event => {
    
    
    // Uncomment in case you only want to allow for the display of only one collapsed item at a time!
    
    // const currentlyActiveAccordionItemHeader = document.querySelector(".h3.active");
    // if(currentlyActiveAccordionItemHeader && currentlyActiveAccordionItemHeader!==accordionItemHeader) {
    //   currentlyActiveAccordionItemHeader.classList.toggle("activo");
    //   currentlyActiveAccordionItemHeader.nextElementSibling.style.maxHeight = 0;
    // }

    h3s.classList.toggle("activo");
    const contenido = h3s.nextElementSibling;
    if(h3s.classList.contains("activo") && fas.classList.contains("fasActivo") ) {
      contenido.style.maxHeight = contenido.scrollHeight + "rem";

    }
    else {
      contenido.classList.remove('style')
    }
    
  });
});
